<template>
  <div>
    <a-table
      layout="fixed"
      style="background-color: white; padding: 8px"
      :loading="loading"
      :columns="columns"
      :data-source="acts"
      :rowKey="r => r.id"
      :pagination="{
        showTotal,
        defaultPageSize,
      }"
      :scroll="{ x: 1650 + 180 }"
      size="small"
    >
      <template slot="name" slot-scope="text">
        <a-popover>
          <template slot="content">
            <div style="font-size: 12px">
              <div>
                <span>Сделка: </span>
                <a target="_blank" :href="`https://rocketcrm.amocrm.ru/leads/detail/${text.lead_id}`">
                  {{ text.name }}
                </a>
              </div>
              <div>
                <span>Компания: </span>
                <a target="_blank" href="https://b2b.rocketcrm.bz/">
                  {{ text.company }}
                </a>
              </div>
              <div>
                <span>Asana: </span>
                <a
                  v-if="text.asana_task_id !== 'null'"
                  target="_blank"
                  :href="`https://app.asana.com/0/0/${text.asana_task_id}/f`"
                >
                  перейти
                </a>
                <span v-else>-</span>
              </div>
              <div>
                <span>B2B: </span>
                <a v-if="text.b2b_id.length" target="_blank" :href="`https://b2b.rocketcrm.bz/${text.b2b_id}/home`">
                  перейти
                </a>
                <span v-else>-</span>
              </div>
            </div>
          </template>

          <span style="overflow: hidden; text-overflow: ellipsis; cursor: default">{{ text.name }}</span>
        </a-popover>
      </template>
      <template slot="planDate" slot-scope="text">
        <span style="white-space: nowrap">{{ text ? moment(text).format('DD.MM.YYYY') : '-' }}</span>
      </template>
      <template slot="num" slot-scope="text">
        {{ text + 1 }}
      </template>
      <template slot="leadStatus" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            <span class="tooltip-text">{{ text }}</span>
          </template>
          {{ text }}
        </a-tooltip>
      </template>
      <template slot="note" slot-scope="text">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span class="tooltip-text">{{ text }}</span>
          </template>
          {{ text }}
        </a-tooltip>
      </template>
      <template slot="leadStatus" slot-scope="text, record">
        <div
          :style="{
            background: record.project.amo_color,
            padding: '2px 8px',
            opacity: 0.85,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            borderRadius: '2px',
          }"
        >
          {{ text }}
        </div>
      </template>
      <template slot="actDate" slot-scope="text">
        <span style="white-space: nowrap">{{ moment(text).format('DD.MM.YYYY') }}</span>
      </template>
      <template slot="dateConfirmation" slot-scope="text">
        <span>{{ text ? moment(text).format('DD.MM.YYYY') : '-' }}</span>
      </template>
      <template slot="linkOfAct" slot-scope="text">
        <a v-if="text && text.length" target="_blank" :href="text">АКТ</a>
        <span v-else>-</span>
      </template>
      <template slot="linkToSignedAct" slot-scope="text">
        <a v-if="text && text.length" target="_blank" :href="text">АКТ</a>
        <span v-else>-</span>
      </template>
      <template slot="budget" slot-scope="text">
        <span>{{ text && isNum(parseInt(text, 10)) ? Number(text).toLocaleString() : '-' }}</span>
      </template>
      <template slot="sumOfAct" slot-scope="text">
        <span>{{ text && isNum(parseInt(text, 10)) ? Number(text).toLocaleString() : '-' }}</span>
      </template>

      <template slot="responsibleUser" slot-scope="text">
        <div style="text-align: left">
          <a-avatar size="small" :src="text.responsible_user_link" />
          {{ text.responsible_user_name || 'Не выбран' }}
        </div>
      </template>

      <template slot="status" slot-scope="text">
        <span v-if="text && text.date_confirmation && text.confirmed_original_http_link">
          <a-icon type="check-circle" theme="twoTone" two-tone-color="#52c41a" />
        </span>
        <span v-else>
          <a-icon type="close-circle" theme="twoTone" two-tone-color="#f5222d" />
        </span>
      </template>
    </a-table>
    <act-stats style="margin-top: 8px" :acts="acts" :pagination="pagination" />
  </div>
</template>

<script>
  import { isNumber } from 'lodash'
  import moment from 'moment'
  import { columns } from './columns'
  import ActStats from './ActStats'

  export default {
    name: 'ActsTable',
    props: {
      acts: Array,
      loading: Boolean,
      defaultPageSize: Number,
    },
    components: { ActStats },
    data() {
      return {
        moment,
        columns,
        pagination: {},
      }
    },

    methods: {
      showTotal(page, pageSize) {
        this.pagination = { page, pageSize }
      },
      isNum(val) {
        return isNumber(val)
      },
    },
  }
</script>

<style scoped lang="scss">
  /deep/ {
    .ant-table {
      font-size: 0.95rem;
      border: 1px solid #f0f0f0;
      .stat-completed {
        color: #52c41a;
        /*font-weight: 500;*/
      }
      .ant-table-thead {
        white-space: nowrap;
      }
    }
    .ant-table td {
      white-space: nowrap;
    }

    .acts {
      &__tooltip-text {
        font-size: 12px !important;
      }
      &__name {
        text-align: left !important;
      }
      &__type-of-lead span {
        white-space: normal !important;
      }
      &__note span,
      &__responsible-user span,
      &__lead-status span {
        white-space: nowrap;
      }
    }
  }
</style>
