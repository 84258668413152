import moment from 'moment'

export const columns = [
  {
    title: 'Название',
    dataIndex: 'project',
    key: 'name',
    scopedSlots: { customRender: 'name' },
    ellipsis: true,
    align: 'center',
    class: 'acts__name',
    filterMultiple: false,
    sorter: (a, b) => a.project.name.length - b.project.name.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'План дата акта',
    dataIndex: 'plan_date',
    key: 'planDate',
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'planDate' },
    sorter: (a, b) => {
      const aDate = a.plan_date ? moment(a.plan_date).unix() : moment('1990-01-01').unix()
      const bDate = b.plan_date ? moment(b.plan_date).unix() : moment('1990-01-01').unix()
      return aDate - bDate
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Статус сделки',
    dataIndex: 'project.status',
    key: 'leadStatus',
    class: 'acts__lead-status',
    align: 'center',
    width: 180,
    ellipsis: true,
    scopedSlots: { customRender: 'leadStatus' },
    sorter: (a, b) => a.project.status.length - b.project.status.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Тип сделки',
    dataIndex: 'project.type',
    key: 'typeOfLead',
    align: 'center',
    class: 'acts__type-of-lead',
    scopedSlots: { customRender: 'typeOfLead' },
    width: 180,
    ellipsis: true,
    sorter: (a, b) => a.project.type.length - b.project.type.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Бюджет',
    dataIndex: 'project.budget',
    key: 'budget',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'budget' },
    sorter: (a, b) => +a.project.budget - +b.project.budget,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Номер акта',
    dataIndex: 'act_number',
    key: 'numberOfAct',
    align: 'center',
    width: 90,
    sorter: (a, b) => +a.act_number - +b.act_number,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Дата акта',
    dataIndex: 'act_date',
    key: 'dateOfAct',
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'actDate' },
    sorter: (a, b) => {
      const aDate = a.act_date ? moment(a.act_date).unix() : moment('1990-01-01').unix()
      const bDate = b.act_date ? moment(b.act_date).unix() : moment('1990-01-01').unix()
      return aDate - bDate
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Сумма акта',
    dataIndex: 'cost',
    key: 'sumOfAct',
    align: 'center',
    width: 120,
    scopedSlots: { customRender: 'sumOfAct' },
    sorter: (a, b) => +a.cost - +b.cost,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Ссылка на акт',
    dataIndex: 'original_http_link',
    key: 'linkOfAct',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'linkOfAct' },
  },
  {
    title: 'Дата подписания',
    dataIndex: 'date_confirmation',
    key: 'dateOfSigning',
    align: 'center',
    width: 140,
    scopedSlots: { customRender: 'dateConfirmation' },
    sorter: (a, b) => {
      const aDate = a.date_confirmation ? moment(a.date_confirmation).unix() : moment('1990-01-01').unix()
      const bDate = b.date_confirmation ? moment(b.date_confirmation).unix() : moment('1990-01-01').unix()
      return aDate - bDate
    },
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Подписанный акт',
    dataIndex: 'confirmed_original_http_link',
    key: 'linkToSignedAct',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'linkToSignedAct' },
  },
  {
    title: 'Ответственный',
    key: 'responsibleUser',
    class: 'acts__responsible-user',
    scopedSlots: { customRender: 'responsibleUser' },
    align: 'center',
    width: 180,
    sorter: (a, b) => +a.project.project_manager_id - +b.project.project_manager_id,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Примечание',
    dataIndex: 'note',
    key: 'note',
    class: 'acts__note',
    align: 'center',
    scopedSlots: { customRender: 'note' },
    ellipsis: true,
    width: 100,
    sorter: (a, b) => a.note.length - b.note.length,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Статус',
    key: 'status',
    align: 'center',
    width: 100,
    scopedSlots: { customRender: 'status' },
    sorter: (a, b) => {
      const abc = a.date_confirmation && a.confirmed_original_http_link ? 2 : 0
      const bbc = b.date_confirmation && b.confirmed_original_http_link ? 3 : 0
      return abc - bbc
    },
    sortDirections: ['descend', 'ascend'],
  },
]
