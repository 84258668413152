var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-table',{staticStyle:{"background-color":"white","padding":"8px"},attrs:{"layout":"fixed","loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.acts,"rowKey":function (r) { return r.id; },"pagination":{
      showTotal: _vm.showTotal,
      defaultPageSize: _vm.defaultPageSize,
    },"scroll":{ x: 1650 + 180 },"size":"small"},scopedSlots:_vm._u([{key:"name",fn:function(text){return [_c('a-popover',[_c('template',{slot:"content"},[_c('div',{staticStyle:{"font-size":"12px"}},[_c('div',[_c('span',[_vm._v("Сделка: ")]),_c('a',{attrs:{"target":"_blank","href":("https://rocketcrm.amocrm.ru/leads/detail/" + (text.lead_id))}},[_vm._v(" "+_vm._s(text.name)+" ")])]),_c('div',[_c('span',[_vm._v("Компания: ")]),_c('a',{attrs:{"target":"_blank","href":"https://b2b.rocketcrm.bz/"}},[_vm._v(" "+_vm._s(text.company)+" ")])]),_c('div',[_c('span',[_vm._v("Asana: ")]),(text.asana_task_id !== 'null')?_c('a',{attrs:{"target":"_blank","href":("https://app.asana.com/0/0/" + (text.asana_task_id) + "/f")}},[_vm._v(" перейти ")]):_c('span',[_vm._v("-")])]),_c('div',[_c('span',[_vm._v("B2B: ")]),(text.b2b_id.length)?_c('a',{attrs:{"target":"_blank","href":("https://b2b.rocketcrm.bz/" + (text.b2b_id) + "/home")}},[_vm._v(" перейти ")]):_c('span',[_vm._v("-")])])])]),_c('span',{staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","cursor":"default"}},[_vm._v(_vm._s(text.name))])],2)]}},{key:"planDate",fn:function(text){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(text ? _vm.moment(text).format('DD.MM.YYYY') : '-'))])]}},{key:"num",fn:function(text){return [_vm._v(" "+_vm._s(text + 1)+" ")]}},{key:"leadStatus",fn:function(text, record){return [_c('div',{style:({
          background: record.project.amo_color,
          padding: '2px 8px',
          opacity: 0.85,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          borderRadius: '2px',
        })},[_vm._v(" "+_vm._s(text)+" ")])]}},{key:"note",fn:function(text){return [_c('a-tooltip',{attrs:{"placement":"topLeft"}},[_c('template',{slot:"title"},[_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(text))])]),_vm._v(" "+_vm._s(text)+" ")],2)]}},{key:"actDate",fn:function(text){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.moment(text).format('DD.MM.YYYY')))])]}},{key:"dateConfirmation",fn:function(text){return [_c('span',[_vm._v(_vm._s(text ? _vm.moment(text).format('DD.MM.YYYY') : '-'))])]}},{key:"linkOfAct",fn:function(text){return [(text && text.length)?_c('a',{attrs:{"target":"_blank","href":text}},[_vm._v("АКТ")]):_c('span',[_vm._v("-")])]}},{key:"linkToSignedAct",fn:function(text){return [(text && text.length)?_c('a',{attrs:{"target":"_blank","href":text}},[_vm._v("АКТ")]):_c('span',[_vm._v("-")])]}},{key:"budget",fn:function(text){return [_c('span',[_vm._v(_vm._s(text && _vm.isNum(parseInt(text, 10)) ? Number(text).toLocaleString() : '-'))])]}},{key:"sumOfAct",fn:function(text){return [_c('span',[_vm._v(_vm._s(text && _vm.isNum(parseInt(text, 10)) ? Number(text).toLocaleString() : '-'))])]}},{key:"responsibleUser",fn:function(text){return [_c('div',{staticStyle:{"text-align":"left"}},[_c('a-avatar',{attrs:{"size":"small","src":text.responsible_user_link}}),_vm._v(" "+_vm._s(text.responsible_user_name || 'Не выбран')+" ")],1)]}},{key:"status",fn:function(text){return [(text && text.date_confirmation && text.confirmed_original_http_link)?_c('span',[_c('a-icon',{attrs:{"type":"check-circle","theme":"twoTone","two-tone-color":"#52c41a"}})],1):_c('span',[_c('a-icon',{attrs:{"type":"close-circle","theme":"twoTone","two-tone-color":"#f5222d"}})],1)]}}])}),_c('act-stats',{staticStyle:{"margin-top":"8px"},attrs:{"acts":_vm.acts,"pagination":_vm.pagination}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }