<template>
  <a-card style="border: none">
    <a-row :gutter="[8, 8]">
      <a-col :lg="4">
        <a-input
          allowClear
          style="width: 100%"
          placeholder="Название"
          :default-value="values.name"
          @input="e => handleNameInput(e, 'name')"
        />
      </a-col>
      <a-col :lg="4">
        <a-input
          allowClear
          style="width: 100%"
          placeholder="Клиент"
          :default-value="values.client"
          @input="e => handleNameInput(e, 'client')"
        />
      </a-col>
      <a-col :lg="4">
        <select-filter
          v-model="values.saleManagers"
          :loading="loading"
          :data="saleManagers"
          placeholder="Менеджер по продажам"
          name="saleManager"
          @update:select="updateSelect"
          :default-value="values"
        />
      </a-col>
      <a-col :lg="4">
        <select-filter
          v-model="values.projectManagers"
          :loading="loading"
          :data="projectManagers"
          placeholder="Менеджер проекта"
          name="projectManager"
          @update:select="updateSelect"
          :default-value="values"
        />
      </a-col>
      <a-col :lg="4">
        <select-filter
          v-model="values.responsibleUsers"
          :loading="loading"
          :data="responsibleUsers"
          name="responsibleUsers"
          placeholder="Ответственный за
        акт"
          @update:select="updateSelect"
          :default-value="values"
        />
      </a-col>
      <a-col :lg="4">
        <select-filter
          v-model="values.typeOfLead"
          :loading="loading"
          :data="typeOfLead"
          placeholder="Тип сделки"
          name="typeOfLead"
          @update:select="updateSelect"
          :default-value="values"
        />
      </a-col>
    </a-row>
    <a-row :gutter="[8, 8]">
      <a-col :lg="6">
        <div class="acts__date-picker">
          <span class="acts__label"> План дата акта</span>
          <a-range-picker
            :default-value="values && values.planDate && values.planDate.map(d => moment(d, 'DD.MM.YYYY'))"
            style="width: 100%"
            format="DD.MM.YYYY"
            @change="onChangePlanDate"
          />
        </div>
      </a-col>
      <a-col :lg="6">
        <div class="acts__date-picker">
          <span class="acts__label">Период подписания</span>
          <a-range-picker
            :default-value="values && values.dateConfirm && values.dateConfirm.map(d => moment(d, 'DD.MM.YYYY'))"
            style="width: 100%"
            format="DD.MM.YYYY"
            @change="onChangeConfirm"
          />
        </div>
      </a-col>
      <a-col :lg="7">
        <div class="acts__date-picker">
          <span class="acts__label">Период формирования акта</span>
          <a-range-picker
            :default-value="values && values.actDate && values.actDate.map(d => moment(d, 'DD.MM.YYYY'))"
            style="width: 100%"
            format="DD.MM.YYYY"
            name="period_form_act"
            @change="onChangeDateAct"
          />
        </div>
      </a-col>
      <a-col :lg="3">
        <select-filter
          v-model="values.status"
          :loading="loading"
          :data="status"
          placeholder="Статус сделки"
          name="statusOfLead"
          @update:select="updateSelect"
          :default-value="values"
        />
      </a-col>
      <a-col :lg="2">
        <select-filter
          v-model="values.actStatus"
          allowClear
          :multiple="false"
          :loading="loading"
          :data="actStatus"
          placeholder="Статус акта"
          name="actStatus"
          @update:select="updateSelect"
          :default-value="values"
        />
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
  import SelectFilter from './SelectFilter'
  import { compact, debounce, isEmpty, isEqual } from 'lodash'
  import moment from 'moment'
  import qs from 'qs'

  export default {
    name: 'FilterContainer',
    components: { SelectFilter },
    props: {
      saleManagers: Array,
      projectManagers: Array,
      responsibleUsers: Array,
      actStatus: Array,
      loading: Boolean,
      typeOfLead: Array,
      status: Array,
    },

    data() {
      return {
        moment,
        values: {},
      }
    },
    beforeMount() {
      this.values = this.$route.query
    },
    methods: {
      updateSelect(val) {
        if (typeof Object.values(val)[0] !== 'number' && !Object.values(val)[0].length) {
          this.$delete(this.values, Object.keys(val)[0])
        } else this.values = { ...this.values, ...val }
      },
      handleNameInput(val, name) {
        const value = val.target.value
        if (!value.length) {
          this.$delete(this.values, name)
        } else this.values = { ...this.values, [name]: value.toLowerCase() }
      },
      onChangeDateAct(date, actDate) {
        if (compact(actDate.map(e => e.length === 0)).length === 2) {
          this.$delete(this.values, 'actDate')
        }
        this.values = { ...this.values, actDate }
      },
      onChangeConfirm(date, dateConfirm) {
        if (compact(dateConfirm.map(e => e.length === 0)).length === 2) {
          this.$delete(this.values, 'dateConfirm')
        } else {
          this.values = { ...this.values, dateConfirm }
        }
      },
      onChangePlanDate(date, planDate) {
        if (compact(planDate.map(e => e.length === 0)).length === 2) {
          this.$delete(this.values, 'planDate')
        } else {
          this.values = { ...this.values, planDate }
        }
      },
    },
    watch: {
      deep: true,
      values: debounce(async function (query) {
        if (
          !isEqual(this.$route.query, query) ||
          !isEqual(this.$route.query, this.values) ||
          !isEqual(qs.parse(window.location.search.slice(1)), this.values)
        ) {
          await this.$router
            .replace({
              path: this.$route.path,
              query,
            })
            .catch(() => {})
          await this.$store.dispatch('acts/getActs', !isEmpty(query) ? query : {})
        }
      }, 500),
    },
  }
</script>

<style scoped lang="scss">
  .acts {
    &__date-picker {
      display: inline-flex;
      white-space: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:last-child {
        margin-left: 8px;
      }

      & span {
        margin-right: 8px;
      }
    }
    &__label {
      font-size: 12px;
    }
  }
</style>
