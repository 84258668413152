<template>
  <div>
    <filter-container
      :saleManagers="saleManagers"
      :projectManagers="projectManagers"
      :typeOfLead="getType"
      :responsibleUsers="responsibleUsers"
      :status="getStatus"
      :actStatus="actStatuses"
    />
    <acts-table
      style="margin: 8px 0"
      :acts="acts"
      :loading="loading"
      :total="total"
      :defaultPageSize="defaultPageSize"
    />
  </div>
</template>

<script>
  import ActsTable from './ActsTable'
  import FilterContainer from './filters/FilterContainer'

  export default {
    name: 'Acts',
    components: { FilterContainer, ActsTable },

    data() {
      return {
        defaultPageSize: 15,
        value: [],
        params: {},
        query: {},
        actStatuses: [
          {
            id: 1,
            name: 'Подписан',
          },
          { id: 0, name: 'Не подписан' },
          { id: 'all', name: 'Все' },
        ],
      }
    },
    async mounted() {
      this.params = this.$route.params
      this.query = this.$route.query

      await this.getData()
    },
    methods: {
      async getData() {
        await this.$router
          .replace({
            path: this.$route.path,
            query: this.query,
          })
          .catch(() => {})
        await this.$store.dispatch('acts/getActs', {
          ...this.params,
          ...this.query,
        })
      },
    },
    computed: {
      saleManagers() {
        return this.$store.getters['acts/getSaleManagers']
      },
      responsibleUsers() {
        return this.$store.getters['acts/getResponsibleUsers']
      },
      getType() {
        return this.$store.getters['acts/getType']
      },
      getStatus() {
        return this.$store.getters['acts/getStatus']
      },
      projectManagers() {
        return this.$store.getters['acts/getProjectManagers']
      },
      loading() {
        return this.$store.getters['acts/getLoadingActs']
      },
      total() {
        return this.$store.getters['acts/getCountActs']
      },
      acts() {
        return this.$store.getters['acts/getActs']
      },
    },
  }
</script>

<style lang="scss" scoped></style>
