<template>
  <a-card title="Итого актов" style="border: none">
    <div style="display: flex; align-items: center">
      <div>
        подписано -
        <span style="color: #52c41a">{{ getActsSum.confirm.toLocaleString() }}</span> | не подписано -
        <span style="color: #f5222d">{{ getActsSum.notConfirm.toLocaleString() }}</span>
      </div>
      <div v-if="pagination.pageSize" style="margin-left: auto">
        Отображено {{ pagination.pageSize[0] }}-{{ pagination.pageSize[1] }}
        из
        {{ pagination.page }}
      </div>
    </div>
  </a-card>
</template>

<script>
  import { sumBy, filter } from 'lodash'

  export default {
    name: 'ActStats',
    props: {
      acts: Array,
      pagination: {},
    },

    computed: {
      getActsSum() {
        const filteredActsConfirm = filter(this.acts, a => a.date_confirmation && a.confirmed_original_http_link)
        const filteredActsNotConfirm = filter(this.acts, a => !a.date_confirmation && !a.confirmed_original_http_link)
        return { confirm: sumBy(filteredActsConfirm, 'cost'), notConfirm: sumBy(filteredActsNotConfirm, 'cost') }
      },
    },
  }
</script>

<style scoped lang="scss">
  .acts {
    &__stats {
      margin: 0;
      padding: 0;
      list-style: none;
      vertical-align: middle;

      & li {
        vertical-align: middle;
        padding: 2px 0;
      }
    }
  }
</style>
