<template>
  <div>
    <a-select
      show-search
      :mode="multiple ? 'multiple' : ''"
      style="width: 100%"
      @change="handleNameInput"
      :placeholder="placeholder"
      :loading="loading"
      :filter-option="true"
      option-filter-prop="name"
      :maxTagCount="1"
      :default-value="
        name !== 'actStatus'
          ? defaultValue[name] &&
            defaultValue[name].map(e => {
              const returnId = +e
              return isNaN(returnId) ? e : returnId
            })
          : defaultValue[name]
      "
    >
      <template v-if="name !== 'statusOfLead'">
        <a-select-option
          v-for="item in refData"
          :key="item.id"
          :value="name === 'typeOfLead' || name === 'actStatus' ? item.name : item.id"
          :name="item.name"
        >
          <a-avatar v-if="item.link" size="small" :src="item.link" /> {{ item.name }}
        </a-select-option>
      </template>
      <template v-else>
        <a-select-opt-group v-for="e in data" :label="e.name" :key="e.id">
          <a-select-option v-for="item in e.values" :key="item.id" :value="item.name" :name="item.name">
            {{ item.name }}
          </a-select-option>
        </a-select-opt-group>
      </template>
    </a-select>
  </div>
</template>

<script>
  import { uniqBy, isNaN } from 'lodash'

  export default {
    name: 'SelectFilter',
    props: {
      placeholder: String,
      loading: Boolean,
      data: Array,
      name: String,
      defaultValue: Object,
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return { uniqBy, isNaN }
    },
    computed: {
      refData() {
        const filterData = uniqBy(this.data, 'id').filter(e => e && e.name)
        if (this.name === 'responsibleUsers') {
          return filterData.concat({ id: 0, name: 'Не выбран', link: 'none' })
        }
        return filterData
      },
    },
    methods: {
      handleNameInput(val) {
        this.$emit(`update:select`, { [this.name]: val })
      },
      filterOption(input, option) {
        if (
          Array.isArray(option.componentOptions.children) &&
          option.componentOptions.children[0] &&
          option.componentOptions.children[0].text
        )
          return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        else return option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
    },
  }
</script>

<style scoped lang="scss">
  .ant-select::v-deep .ant-select .ant-select-dropdown-menu-item-group-title {
    white-space: nowrap;
    color: red;
  }

  .select-filter {
    /deep/ {
      .ant-select-dropdown-menu-item-group-title {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
    }
  }
</style>
